<template>
  <Toast />

  <div class="card">
    <DataTable
      ref="dt"
      :value="records"
      dataKey="id"
      :paginator="true"
      :rows="10"
      class="dt"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
      v-model:filters="filters"
      :globalFilterFields="['title','subtitle','keywords','author']"
      stateStorage="session" stateKey="eternite"
    >
      <template #header>
        <div class="table-header pop20">
          <div class="p-d-flex p-jc-between">
            <div>Manage eternite</div>
            <div>
              <Button
                label="New item"
                icon="pi pi-plus"
                class="p-button-success p-mr-2"
                @click="openNew"
              />
            </div>
            <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
          </div>
        </div>
      </template>

      <Column field="id" header="Id" :sortable="true"></Column>
      <Column header="Image">
        <template #body="slotProps">
          <img
            :src="
              `${$config.images.server}/images/${slotProps.data.media}?width=80&height=80&fit=contain&background=ffffff`
            "
            :alt="slotProps.data.title"
            class="small_record_image"
            v-if="slotProps.data.media"
          />
          <img
            :src="'/record_medias/noimage.png'"
            class="record_image"
            v-else
          />
        </template>
      </Column>
      <Column field="title" header="Title" :sortable="true"></Column>
      <Column field="subtitle" header="Subtitle" :sortable="true"></Column>
      <Column field="recordcategory" header="Category" :sortable="true">
        <template #body="slotProps">
          {{ showCategory(slotProps.data.recordcategory) }}
        </template>
      </Column>
      <Column field="keywords" header="Keywords" :sortable="true"></Column>
      <Column field="author" header="Author" :sortable="true"></Column>
      <Column field="updatedAt" header="Modified" :sortable="true"></Column>
      <Column>
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            @click="editrecord(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning"
            @click="confirmDeleterecord(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog
    header="Upload media"
    v-model:visible="displayUploadMedia"
    :style="{ width: '50vw' }"
  >
    <FileUpload
      ref="upm"
      name="mediaFile"
      :multiple="true"
      :fileLimit="5"
      :maxFileSize="10000000"
      :url="`${$config.iwib.server}/records/uploadMedia`"
      @before-send="prepareUploadMedia"
      @upload="onUploadMedia"
    >
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </Dialog>

  <Dialog
    header="Upload cover image"
    v-model:visible="displayUpload"
    :style="{ width: '50vw' }"
  >
    <FileUpload
      ref="up"
      name="mediaFile"
      :multiple="false"
      accept="image/*"
      :fileLimit="1"
      :maxFileSize="1000000"
      :url="`${$config.iwib.server}/records/upload`"
      @before-send="prepareUpload"
      @upload="onUpload"
    >
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </Dialog>

  <Dialog
    v-model:visible="recordDialog"
    :style="{ width: '450px' }"
    header="Eternite record details"
    :maximizable="true"
    :modal="true"
    class="p-fluid"
  >
    <Panel header="Cover image" class="p-pb-4" :toggleable="true">
      <template #icons>
        <button class="p-panel-header-icon p-link p-mr-2" @click="openUpload">
          <span class="pi pi-upload"></span>
        </button>
      </template>
      <p class="center-image">
        <img
          ref="currentImage"
          :src="
            `${$config.images.server}/images/${record.media}?width=150&height=150&fit=contain&background=ffffff`
          "
          :alt="record.title"
          class="big_record_image"
          v-if="record.media"
        />
        <img :src="'/record_medias/noimage.png'" class="record_image" v-else />
      </p>
    </Panel>
    <Panel header="Media files" class="p-pb-4" :toggleable="true">
      <template #icons>
        <button
          class="p-panel-header-icon p-link p-mr-2"
          @click="openUploadMedia"
        >
          <span class="pi pi-upload"></span>
        </button>
      </template>
      <div class="p-field">
        <ul>
          <li v-for="media in relatedmedia" :key="media">
            {{ media.name }}
          </li>
        </ul>
      </div>
    </Panel>

    <Panel header="Record properties" class="p-pb-4" :toggleable="true">
      <div class="p-field">
        <label for="title">Title</label>
        <InputText
          id="title"
          v-model.trim="record.title"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.title }"
        />
        <small class="p-error" v-if="submitted && !record.title"
          >Title is required.</small
        >
      </div>
      <div class="p-field">
        <label for="subtitle">Subtitle</label>
        <InputText id="subtitle" v-model.trim="record.subtitle" />
      </div>
      <div class="p-field">
        <label for="ddcategory">Record category</label>
        <Dropdown
          id="ddcategory"
          v-model="record.recordcategory"
          :options="categories"
          optionLabel="name"
          optionValue="code"
          placeholder="Select a category"
        />
      </div>
      <div class="p-field">
        <label for="keywords">Keywords (separate keywords with ;)</label>
        <InputText id="keywords" v-model.trim="record.keywords" />
      </div>
      <div class="p-field">
        <label for="shortdescription">Shortdescription</label>
        <InputText
          id="shortdescription"
          v-model.trim="record.shortdescription"
        />
      </div>
    </Panel>

    <Panel header="Description" class="p-pb-4" :toggleable="true">
      <QuillEditor theme="snow" id="description" v-model="record.description" />
<!--       <Editor
        id="description"
        v-model="record.description"
        editorStyle="height: 320px"
      />
 -->    </Panel>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="hideDialog"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        @click="saverecord(false)"
      />
      <Button
        label="Save &amp; close"
        icon="pi pi-check"
        class="p-button-text"
        @click="saverecord(true)"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="deleterecordDialog"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="record"
        >Are you sure you want to delete the record <b>{{ record.title }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleterecordDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleterecord"
      />
    </template>
  </Dialog>
</template>

<script>
import recordService from "../../services/record_service"
import Dropdown from "primevue/dropdown"
import {FilterMatchMode} from 'primevue/api';

export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      relatedmedia: [],
      records: null,
      recordDialog: false,
      deleterecordDialog: false,
      record: {},
      submitted: false,
      isnew: false,
      files: [],
      displayUpload: false,
      displayUploadMedia: false,
      filters: null,
      categories: [
        { name: "Lees", code: 1 },
        { name: "Bekijk", code: 2 },
        { name: "Beluister", code: 3 },
      ],
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
    this.filters = {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}}
  },
  mounted() {
    this.recordservice.getEternite().then((data) => (this.records = data))
  },
  methods: {
    showCategory(cat) {
      switch (cat) {
        case 1:
          return "Lees"
        case 2:
          return "Bekijk"
        case 3:
          return "Beluister"
      }
      return "Error"
    },
    openUpload() {
      this.displayUpload = true
    },
    openUploadMedia() {
      this.displayUploadMedia = true
    },
    openNew() {
      this.record = {}
      this.submitted = false
      this.recordDialog = true
      this.isnew = true
    },
    hideDialog() {
      this.recordDialog = false
      this.submitted = false
    },
    saverecord(close) {
      this.submitted = true
      //defaults for eternite
      this.record.recordtype = 3
      this.record.connectedId = 0
      this.record.author = JSON.parse(localStorage.getItem("user")).username
      this.record.coordinates = "0"

      if (this.record.title.trim()) {
        if (this.record.id) {
          this.recordservice
            .update(this.record)
            .then(() => {
              this.recordservice
                .getEternite()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Updated",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        } else {
          this.recordservice
            .add(this.record)
            .then(() => {
              this.recordservice
                .getEternite()
                .then((data) => (this.records = data))
              this.$toast.add({
                severity: "success",
                summary: "Successful",
                detail: "record Created",
                life: 3000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err,
                life: 3000,
              })
            })
        }
        if (close) {
          this.recordDialog = false
          this.record = {}
        }
      }
    },
    editrecord(record) {
      this.isnew = false
      this.record = { ...record }
      this.relatedmedia = JSON.parse(this.record.relatedmedia)
      this.recordDialog = true
    },
    confirmDeleterecord(record) {
      this.record = record
      this.deleterecordDialog = true
    },
    deleterecord() {
      this.recordservice
        .delete(this.record.id)
        .then(() => {
          this.recordservice.getEternite().then((data) => (this.records = data))
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "record Created",
            life: 3000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          })
        })
      this.deleterecordDialog = false
      this.record = {}
    },
    onUpload(event) {
      this.record.media = event.files[0].name
      this.displayUpload = false
    },
    prepareUpload(event) {
      let user = JSON.parse(localStorage.getItem("user"))
      event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
    },
    onUploadMedia(event) {
      event.files.forEach((file) => {
        this.relatedmedia.push({
          name: file.name.replace(/ /g, "_"),
          type: file.type,
          size: file.size,
        })
      })
      this.record.relatedmedia = JSON.stringify(this.relatedmedia)
      this.displayUploadMedia = false
    },
    prepareUploadMedia(event) {
      let user = JSON.parse(localStorage.getItem("user"))
      event.xhr.setRequestHeader("Authorization", "Bearer " + user.token)
    },
  },
}
</script>

<style scoped>
.small_record_image {
  width: 100px;
}
.big_record_image {
  width: 300px;
}
.center-image {
  text-align: center;
}
</style>
